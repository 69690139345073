import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import axios from "axios"

import Link from "../UI/Link"
import ExternalLinkIcon from "../../images/icon-external-link.inline.svg"
import { validateForm } from "../../utils"
import queryString from "query-string"
import {
  sectionClass,
  rowClass,
  externalLinkIconClass,
  formControlClass,
  successClass,
  sendBtn,
  checkboxGroup,
  checkboxContainer,
  checkmark,
  imageClass
} from "./NewsletterSubscription.module.scss"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment newsletterSubscriptionFragment on WpPage_Flexlayouts_FlexibleLayouts_NewsletterSubscription {
    sectionHeading
    headingType
    description
    link {
      url
      title
    }
    pivacyLink
    termsLink
    backgroundImage {
      ...FullWidthImageQuery
    }
  }
`

const NewsletterSubscription = ({
  sectionHeading,
  description,
  link,
  backgroundImage,
  pivacyLink,
  termsLink,
  headingType
}) => {
  const [isSubmitting, setIsSubmitting] = useState()
  const [status, setStatus] = useState({ initial: true })
  const [formValidated, setFormValidated] = useState(false)

  const validate = event => {
    const form = event.currentTarget
    const isValidForm = validateForm(form)
    if (isValidForm) {
      event.stopPropagation()
    }
    setFormValidated(isValidForm)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget

    const data = {
      email: form.elements.email.value
    }

    const postForm = async () => {
      try {
        setIsSubmitting(true)
        const response = await axios.post(
          `${process.env.NEWSLETTER_FORM_URL}`,
          queryString.stringify(data)
        )
        setStatus({
          success: `Thank you for subscribing to our newsletter.`,
          initial: false
        })
      } catch (exception) {
        setIsSubmitting(false)
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`
        })
      }
    }
    postForm()
  }

  const image = getImage(backgroundImage?.localFile)

  return (
    <section className={sectionClass}>
      <GatsbyImage
        className={`${imageClass} position-absolute h-100`}
        image={image}
        alt={backgroundImage?.altText}
      />

      <Container>
        <Row className={rowClass}>
          <Col md={10} className="mx-auto">
            <SectionHeading
              className="pt-3 pb-md-2"
              type={headingType}
              text={sectionHeading}
            />            
            {description && (
              <Row className="mb-mid4 lead">
                <Col>
                  <p className="mb-0">{description}</p>
                  {link && (
                    <Link classes="text-secondary" link={link.url}>
                      <>
                        <span>{link.title}</span>{" "}
                        <ExternalLinkIcon className={externalLinkIconClass} />
                      </>
                    </Link>
                  )}
                </Col>
              </Row>
            )}

            <Form
              id="newsletterForm"
              validated={formValidated}
              onSubmit={handleSubmit}
              onChange={validate}
              method="post">
              {status && status.initial && (
                <>
                  <Row className="flex-column flex-lg-row">
                    <Col>
                      <Form.Group controlId="email">
                        <Form.Control
                          className={formControlClass}
                          type="email"
                          name="email"
                          placeholder="Email address"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={6} lg={7}>
                      <div className={`${checkboxGroup} form-group form-check`}>
                        <label
                          className={`${checkboxContainer} form-check-label text-start`}
                          htmlFor="terms">
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            id="terms"
                            name="terms"
                            required
                          />
                          <span class={checkmark}></span>I agree to the EPT{" "}
                          <Link
                            classes="linkClass"
                            link={pivacyLink}
                            title="Privacy Policy"
                          />
                          {termsLink ? (
                            <>
                              {" "}
                              and{" "}
                              <Link
                                classes="linkClass"
                                link={termsLink}
                                title="Terms"
                              />
                            </>
                          ) : (
                            "."
                          )}
                        </label>
                      </div>
                    </Col>
                    <Col md={6} lg={5}>
                      <Form.Group className="text-sm-end">
                        <Button
                          type="submit"
                          disabled={!formValidated}
                          className={sendBtn}>
                          Subscribe
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              {status && status.success && (
                <div className={successClass}>
                  <span>{status.success}</span>
                </div>
              )}
              {status && status.exception && (
                <Alert variant="danger" className="my-5">
                  <Alert.Heading>{status.exception}</Alert.Heading>
                </Alert>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default NewsletterSubscription
