// extracted by mini-css-extract-plugin
export var sectionClass = "NewsletterSubscription-module--sectionClass--5dlK-";
export var formControlClass = "NewsletterSubscription-module--formControlClass--0W3KP";
export var rowClass = "NewsletterSubscription-module--rowClass--iSlwu";
export var externalLinkIconClass = "NewsletterSubscription-module--externalLinkIconClass--Y8SZO";
export var successClass = "NewsletterSubscription-module--successClass--jrKk4";
export var sendBtn = "NewsletterSubscription-module--sendBtn--aXz6o";
export var checkboxGroup = "NewsletterSubscription-module--checkbox-group--amQAs";
export var checkboxContainer = "NewsletterSubscription-module--checkbox-container--XTT-S";
export var checkmark = "NewsletterSubscription-module--checkmark--5y-HV";
export var imageClass = "NewsletterSubscription-module--imageClass--ZjWEd";